import React from 'react';
import { Typography, IconButton, alpha } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link as RouterLink } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import './ContactUs.css'
import "@fontsource-variable/dm-sans"
import "@fontsource/dm-serif-display"

const mobileContainer = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}

const container = {
    paddingBottom: '8.625rem'
}

// const contactUsContainer = {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     width: '30rem',
//     borderRadius: '0.5rem',
//     padding: '1.5rem',
//     backgroundColor: alpha('#5786a2', 0.1),
// }

// const mobileContactUsContainer = {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     marginTop: '2rem',
//     padding: '1.5rem',
//     maxWidth: '30rem'
// }

const socialMediaContainer = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
    borderRadius: '0.5rem',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    width: '20rem',
    marginBottom: '5rem',
    backgroundColor: alpha('#5786a2', 0.1),
}

const mobileSocialMediaContainer = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: "center",
    width: '100%',
    height: '18rem',
}

const emailContainer = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
    borderRadius: '0.5rem',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    width: '20rem',
    backgroundColor: alpha('#5786a2', 0.1),
}


const mobileEmailContainer = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
    width: '100%',
    height: '18rem',
    justifyContent: 'center',
    backgroundColor: alpha('#5786a2', 0.1),
}

const title = {
    textAlign: 'center',
    marginTop: '8.625rem',
    fontSize: '4.5rem'

}
const mobileTitle = {
    textAlign: 'center',
    marginTop: '3rem',
    fontSize: '3rem'
}

// const helpText = {
//     fontSize: '2.5rem',
//     marginTop: '0.5rem',
//     fontFamily: 'DM Serif Display'
// }

// const mobileHelpText = {
//     fontSize: '1.75rem',
//     marginTop: '0.5rem',
//     fontFamily: 'DM Serif Display'
// }
// const sendMessageText = {
//     fontSize: '2.5rem',
//     marginTop: '0.5rem',
//     marginBottom: '1rem',
//     fontFamily: 'DM Serif Display'
// }
// const mobileSendMessageText = {
//     fontSize: '1.75rem',
//     marginTop: '0.5rem',
//     marginBottom: '1rem',
//     fontFamily: 'DM Serif Display'
// }

const emailAndSocialTitle = {
    fontSize: '2.5rem',
    ontFamily: 'DM Serif Display'
}

const emailAndSocialMobileTitle = {
    fontSize: '1.75rem',
    ontFamily: 'DM Serif Display'
}

const mainContainer = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
}

export default function ContactUs() {
    const isMobile = useMediaQuery('(max-width:925px)');
    const isMobileText = useMediaQuery('(max-width:515px)');
    return (
        <div style={mainContainer}>
            <div style={isMobile ? mobileContainer : container}>
                <NavBar />
                <Typography color='#5686A1' fontFamily='DM Serif Display' style={isMobileText ? mobileTitle : title}>Contact Us</Typography>
                <div style={isMobile ? { width: '100%' } : { display: 'flex', justifyContent: 'center', marginBottom: '3rem' }}>
                    <div style={isMobile ? { width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' } : { display: 'flex', justifyContent: 'space-around', marginTop: '5rem', width: '75rem' }}>
                        {/* <div style={isMobile ? mobileContactUsContainer : contactUsContainer}>
                            <Typography style={isMobileText ? mobileHelpText : helpText}>We would love to help!</Typography>
                            <Typography style={isMobileText ? mobileSendMessageText : sendMessageText}>Please send us a message</Typography>
                            <TextField
                                required
                                id="outlined-basic-name"
                                label="Name"
                                variant="outlined"
                                InputLabelProps={{ style: { fontFamily: 'DM Sans Variable' } }}
                                inputProps={{ style: { ffontFamily: 'DM Sans Variable' } }}
                                style={{
                                    width: "100%",
                                    marginBottom: '1.5rem'
                                }} />
                            <TextField
                                required
                                id="outlined-basic-emaail"
                                label="Email"
                                variant="outlined"
                                InputLabelProps={{ style: { fontFamily: 'DM Sans Variable' } }}
                                inputProps={{ style: { fontFamily: 'DM Sans Variable' } }}
                                style={{
                                    width: "100%",
                                    marginBottom: '1.5rem'
                                }} />
                            <TextField
                                required
                                id='message'
                                label='Message'
                                rows={10}
                                multiline
                                InputLabelProps={{ style: { fontFamily: 'DM Sans Variable' } }}
                                inputProps={{ style: { fontFamily: 'DM Sans Variable' } }}
                                style={{
                                    width: '100%',
                                    marginBottom: '1.5rem'
                                }} />
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: '#5686A1',
                                    fontSize: '1.25rem',
                                    fontFamily: 'DM Serif Display',
                                    width: '100%',
                                    height: '4rem',
                                    '&:hover': {
                                        backgroundColor: alpha('#5786a2', 0.75)
                                    }
                                }} >
                                Send Message
                            </Button>
                        </div> */}
                        <div style={isMobile ? { width: '100%' } : { display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                            <div style={isMobile ? mobileSocialMediaContainer : socialMediaContainer}>
                                <Typography sx={isMobileText ? emailAndSocialMobileTitle : emailAndSocialTitle}>Social Media</Typography>
                                <div style={{ background: 'red', width: '4rem', height: '0.25rem', marginTop: '1rem', marginBottom: '0.5rem' }} />
                                <div style={{ display: 'flex' }}>
                                    <IconButton component={RouterLink} to='https://www.facebook.com/profile.php?id=61550480783388&mibextid=2JQ9oc'>
                                        <FacebookIcon style={{ fontSize: 50 }} />
                                    </IconButton>
                                    <IconButton component={RouterLink} to='https://www.instagram.com/anyalaskainc?igshid=OGQ5ZDc2ODk2ZA=='>
                                        <InstagramIcon style={{ fontSize: 50 }} />
                                    </IconButton>
                                </div>
                            </div>
                            <div style={isMobile ? mobileEmailContainer : emailContainer}>
                                <Typography sx={isMobileText ? emailAndSocialMobileTitle : emailAndSocialTitle}>Email</Typography>
                                <div style={{ background: 'red', width: '4rem', height: '0.25rem', marginTop: '1rem', marginBottom: '1.5rem' }} />
                                <div style={{ display: 'flex' }}>
                                    <Typography fontFamily='DM Sans Variable' style={{ fontSize: '1.25rem' }}>anyalaskainc@gmail.com</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}