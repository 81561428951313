import React from 'react';
import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from '../assets/logo-2.0-500px.png'
import "@fontsource/poppins/600.css";

const logoWidth = {
    width: '11.5rem',
}
const logoMobile = {
    width: '5rem'
}

const outsideContainer = {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#5686A1',
    width: '100%'
}

const container = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    maxWidth: '100rem',
    width: '100%',
    height: '20.6rem'
}

const mobileContainer = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    height: '20rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
}

const buttons = {
    color: 'white',
    fontSize: '1.0625rem',
    fontFamily: 'Poppins',
    height: '1.5rem',
    width: '8.20rem',
}

const handleClick = () => {
    window['scrollTo']({top: 0, behavior: 'instant' }) 
}

export default function Footer() {
    const isMobile = useMediaQuery('(max-width:745px)');
    return (
        <div style={outsideContainer}>
            <div style={isMobile ? mobileContainer : container}>
                <Button component={RouterLink} to="/OurStory" onClick={() => handleClick()} style={buttons}>Our Story</Button>
                <Button component={RouterLink} to="/ReserveYourSalmon" onClick={() => handleClick()} style={buttons}>Reserve</Button>
                <Button component={RouterLink} to="/"><img alt='logo' draggable='false' src={logo} style={isMobile ? logoMobile : logoWidth} /></Button>
                <Button component={RouterLink} to="/Recipes" onClick={() => handleClick()} style={buttons}>Recipes</Button>
                <Button component={RouterLink} to="/ContactUs" onClick={() => handleClick()} style={buttons}>Contact Us</Button>
            </div>
        </div>
    )
}