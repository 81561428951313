import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Drawer } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import bearLogo from '../assets/Bear-icon-250.png'
import whiteBear from '../assets/white_bear.png'
import "@fontsource/poppins/600.css";

export default function NavBar(props) {
    const {currentBackgroundColor='#5686A1', color='white', position='static', bear='default'} = props
    const isMobile = useMediaQuery('(max-width:1132px)');

    const [isMenuOpen, setIsMenuOpen] = useState(false)

    function handleMenuClick() {
        if (isMobile) {
            isMenuOpen ? setIsMenuOpen(false) : setIsMenuOpen(true);
        }
    }

    const mobileNavBar = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        backgroundColor: currentBackgroundColor,
        height: '5rem',
        position: position
    }

    const navBar = {
        display: 'flex',
        justifyContent:'space-between',
        alignItems: 'center',
        width: '100%',
        height: '10.3125rem',
        backgroundColor: currentBackgroundColor,
        position: position
    }

    const buttons = {
        color: color,
        fontSize: '1.0625rem',
        marginRight: '2.5rem',
        fontFamily: 'Poppins'
    }

    const logoButton = {
        color: 'black', marginLeft: '13.5rem'
    }

    const hamburgerButtons = {
        width: 300,
        color: 'black',
        marginTop: 20,
        fontSize: 20,
        fontFamily: 'Poppins'
    }

    return (
        <React.Fragment>
            {isMobile ?
                (
                    <div style={mobileNavBar}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2, ml: 2 }}
                            onClick={() => handleMenuClick()}
                        >
                            <MenuIcon sx={{color: bear==='default' ? 'black' : 'white', width: '2rem', height: '2rem' }} />
                        </IconButton>
                        <Button component={RouterLink} to="/" onClick={() => handleMenuClick('Home')} style={{marginRight: '1.5rem'}}>
                            <img alt='small logo' draggable='false' src={bear==='default' ? bearLogo : whiteBear} style={{ width: '2.5rem', height: '2.5rem' }} />
                        </Button>
                    </div>)
                :
                (
                    <div style={navBar}>

                        <Button component={RouterLink} to="/" onClick={() => handleMenuClick('Home')} style={logoButton}>
                            <img alt='small logo' draggable='false' src={bear==='default' ? bearLogo : whiteBear} style={{ width: '5.25rem', height: '5.25rem' }} />
                        </Button>
                        <div style={{ marginRight: '13.5rem' }}>
                            <Button component={RouterLink} to="/OurStory" onClick={() => handleMenuClick()} style={buttons}>Our Story</Button>
                            <Button component={RouterLink} to="/ReserveYourSalmon" onClick={() => handleMenuClick()} style={buttons}>Reserve</Button>
                            <Button component={RouterLink} to="/Recipes" onClick={() => handleMenuClick()} style={buttons}>Recipes</Button>
                            <Button component={RouterLink} to="/ContactUs" onClick={() => handleMenuClick()} style={buttons}>Contact Us</Button>
                        </div>
                    </div>
                )}

            <Drawer anchor='left' open={isMenuOpen} onClose={() => setIsMenuOpen(false)} >
                <Button component={RouterLink} to="/" onClick={() => handleMenuClick()} style={hamburgerButtons}>Home</Button>
                <Button component={RouterLink} to="/OurStory" onClick={() => handleMenuClick()} style={hamburgerButtons}>Our Story</Button>
                <Button component={RouterLink} to="/ReserveYourSalmon" onClick={() => handleMenuClick()} style={hamburgerButtons}>Reserve</Button>
                <Button component={RouterLink} to="/Recipes" onClick={() => handleMenuClick()} style={hamburgerButtons}>Recipes</Button>
                <Button component={RouterLink} to="/ContactUs" onClick={() => handleMenuClick()} style={hamburgerButtons}>Contact Us</Button>
            </Drawer>
        </React.Fragment>
    );
}