import React, { useState, useEffect } from 'react';
import { Typography, Button, } from '@mui/material';
import ReservationTable from '../components/ReservationTable.js'
import { getReservations } from '../services/Admin.js';
import NavBar from '../components/NavBar.js';
import Login from '../components/Login.js';
import Footer from '../components/Footer.js';
import { AxiosError } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "@fontsource/dm-serif-display"
import Cookies from 'js-cookie';

export default function Admin() {
    const [reservations, setReservations] = useState([])
    const [isAuthenticated, setIsAuthenticated] = useState(Cookies.get('accessToken') ? true : false)
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const container = {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
    }

    useEffect(() => {
        if (isAuthenticated) {
            getCurrentReservations()
        }
    }, [isAuthenticated])

    const getCurrentReservations = async () => {
        const retrievedReservations = await getReservations();
        if (retrievedReservations === AxiosError) {
            toast.error(retrievedReservations.message, { theme: 'colored' })
            return
        }
        setReservations(retrievedReservations)
    }

    useEffect(() => {
        setIsAuthenticated(Cookies.get('accessToken') ? true : false)
    }, [isLoggedIn])

    const handleLogout = () => {
        Cookies.remove('idToken', { sameSite: 'strict' })
        Cookies.remove('accessToken', { sameSite: 'strict' })
        Cookies.remove('refreshToken', { sameSite: 'strict' })
        setIsAuthenticated(false)
        setIsLoggedIn(false)
    }
    return (
        <React.Fragment>
            {
                isAuthenticated ?
                    <div style={container}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                            <ToastContainer />
                            <NavBar />
                            <Button variant='contained' style={{ width: '12rem', height: '2.5rem', fontSize: '1.5rem', fontFamily: 'DM Serif Display', alignSelf: 'end', marginRight: '1rem', marginTop: '1rem', borderRadius: 0, backgroundColor: '#5786a2' }} onClick={() => handleLogout()}>Logout</Button>
                            <Typography variant='h3' color='white' sx={{ color: '#5686A1', marginTop: '6rem', marginBottom: '3rem', textAlign: 'center' }}>Reservations</Typography>
                            <ReservationTable reservations={reservations} />
                            <Footer />
                        </div>
                    </div> :
                    <Login setIsLoggedIn={setIsLoggedIn} />
            }
        </React.Fragment>
    );
}