import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import family from '../assets/family-about-us.jpg'
import mission from '../assets/mission.png'
import novios from '../assets/Novios.jpg'
import proposal from '../assets/proposal.png'
import wedding from '../assets/wedding.png'
import firstchild from '../assets/firstchild.png'
import deckhand from '../assets/deckhand.png'
import jakeWithFish from '../assets/jake-with-fish.png'
import jack from '../assets/jack.png'
import snowy from '../assets/snowy.png'
import ocean from '../assets/ocean.png'
import useMediaQuery from '@mui/material/useMediaQuery';
import './OurStory.css'
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import cacheImages from '../services/CacheImags';
import "@fontsource-variable/dm-sans"
import "@fontsource/dm-serif-display"

export default function OurStory() {
    const isMobile = useMediaQuery('(max-width:1100px)');

    const [isLoading, setIsLoading] = useState(true);

    const largeContainer = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }

    const mobileContainer = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }

    const columnContainer = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    }

    useEffect(() => {
        const preloadList = [mission, family]
        cacheImages(preloadList, setIsLoading)
    }, [])

    return (
        <>
            {!isLoading && <div style={isMobile ? mobileContainer : largeContainer}>
                <NavBar />
                <Typography color='#5686A1' fontFamily='DM Serif Display' style={isMobile ? { fontSize: '2.5rem', marginTop: '5rem' } : { fontSize: '4.5rem', marginTop: '8.625rem' }}>Our Story</Typography>
                <Typography maxWidth='75rem' textAlign='center' fontFamily='DM Sans Variable' style={isMobile ? { marginTop: '4rem', marginBottom: '4rem', fontSize: '1.15rem', width: '90%' } : { marginTop: '5.4375rem', fontSize: '1.875rem' }}>
                    Our story begins in 2015 when Any and I first met. We were both serving as missionaries for the Church of Jesus Christ of Latter-Day Saints in Chihuahua, Mexico. She was from Panama, and I was from Alaska; you can't get much more polar opposite.
                </Typography>
                <div style={isMobile ? columnContainer : { display: 'flex', alignItems: 'center', width: '100%', marginTop: '12.125rem', justifyContent: 'space-between', maxWidth: '90rem' }}>
                    <img alt='full family' draggable='false' src={family} style={isMobile ? { width: '100%' } : { height: '100%', maxHeight: '41rem' }} />
                    <img alt='mission' draggable='false' src={mission} style={isMobile ? { width: '100%' } : { height: '100%', maxHeight: '41rem' }} />
                </div>
                <div className='background' style={isMobile ? { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', paddingTop: '4rem' } : { display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '48.75rem', marginTop: '9.8125rem' }}>
                    <Typography fontFamily='DM Sans Variable' style={isMobile ? { width: '90%', fontSize: '1.15rem', paddingTop: '1rem', marginBottom: '4rem', textAlign: 'center' } : { width: '55.5rem', marginRight: '11rem', fontSize: '1.875rem' }}>I am 6'5", and she is 5'5", and we were probably the least likely to be together. Yet destiny called, and a short two years later, I visited Any in the country of Panama and came back a month later married to my sweetheart. We needed money, and we needed it fast, so I took a job commercial salmon fishing in the small town of South Naknek, Alaska, with a family friend.</Typography>
                    <img alt='mission' draggable='false' src={novios} style={isMobile ? { width: '100%' } : { width: '27rem' }} />
                </div>
                <div style={isMobile ? columnContainer : { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '15.3125rem', marginBottom: '13rem' }}>
                    <div>
                        {!isMobile && <img alt='proposal' draggable='false' src={proposal} style={isMobile ? { width: '100%' } : { height: '41rem', marginRight: '5rem' }} />}
                        <img alt='wedding' draggable='false' src={wedding} style={isMobile ? { width: '100%' } : { height: '41rem' }} />
                    </div>
                    <Typography fontFamily='DM Sans Variable' style={isMobile ? { width: '90%', textAlign: 'center', fontSize: '1.15rem', marginTop: '4rem', marginBottom: '4rem' } : { width: '73rem', textAlign: 'center', marginTop: '8.125rem', fontSize: '1.875rem' }}>Ever since I stepped foot on that beach, I knew this would be a significant part of our lives! I quickly fell in love with the beautiful place—the bears, the people, the salmon, and especially the lack of modern society had me hooked. We lived on a beach in a quaint cabin, picking nets from sunrise to sundown and sun-up again. We worked, laughed, sweated, played, bled, and didn't sleep too much. A long flight back to Panama six weeks later, I came home to Any with our first Salmon paycheck.</Typography>
                </div>
                <div className='background' style={isMobile ? { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', paddingBottom: '4rem' } : { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <div style={isMobile ? {} : { marginTop: '13.875rem' }}>
                        <img alt='deckhand' draggable='false' src={deckhand} style={isMobile ? { width: '100%' } : { height: '39.125rem', marginRight: '7.3125rem' }} />
                        <img alt='firstchild' draggable='false' src={firstchild} style={isMobile ? { width: '100%' } : { height: '39.125rem' }} />
                    </div>
                    <Typography fontFamily='DM Sans Variable' style={isMobile ? { width: '90%', fontSize: '1.15rem', textAlign: 'center', marginTop: '4rem' } : { width: '90.8rem', textAlign: 'center', marginTop: '4.4375rem', marginBottom: '11.25rem', fontSize: '1.875rem' }}>On a deckhand's wage, we were able to survive and pay bills in Panama, which was such a blessing. Especially after getting the news our first child was already on the way, and we could not have been more excited. We also could not have been more motivated to move ahead in life and provide our kids with a life we felt they deserved. We did all sorts of odd-end jobs trying to pay bills as I also pursued a bachelor's degree online while out of the country. For new parents, nine months sure goes by way too fast!</Typography>
                </div>
                {isMobile ?
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img alt='jake with fish' draggable='false' src={jakeWithFish} style={{ width: '100%' }} />
                        <Typography fontFamily='DM Sans Variable' style={{ fontSize: '1.15rem', width: '90%', textAlign: 'center', marginBottom: '2rem', marginTop: '4rem' }}>Again, we had a successful and beautiful season of catching salmon, but I had never missed someone more in my entire life and wished so badly we could be together on that beautiful beach. At this point, we started to catch wind of our dream as a family: to come out as a family to this peaceful place and provide for our needs. I came home as fast as I could, and we spent the next months in love with our new little family.</Typography>
                        <Typography fontFamily='DM Sans Variable' style={{ fontSize: '1.15rem', width: '90%', textAlign: 'center', marginBottom: '2rem' }}>Later that year, in November, our long-awaited marriage visa to the United States was approved, and we made the trip to Utah! We are forever grateful for loving friends and family that helped us make a smooth landing, especially Dave and Brittney, who provided us with a roof and a job to begin our journey in the US. We lived in St. George for a time, then took a job at Snowbird, plowing snow and floating bills. Samy grew, the snow melted, and the salmon were on their way. We packed up and headed to the last frontier.</Typography>
                        <Typography fontFamily='DM Sans Variable' style={{ fontSize: '1.15rem', width: '90%', textAlign: 'center', marginBottom: '2rem' }}>First, we arrived in Sterling where I was raised and moved into my parents' basement. Even though Any and Samy could not come with me to fish camp, it was still a relief to have them closer as I headed to western Alaska once again. This year I was promoted as a beach boss and captained the boat for the summer. After an extremely successful year and breaking the operations all-time record, I was able to invite my sweetheart and princess out to my favorite beach so they could experience it firsthand the last few days of the season.</Typography>
                        <Typography fontFamily='DM Sans Variable' style={{ fontSize: '1.15rem', width: '90%', textAlign: 'center', marginBottom: '4rem' }}>We left that place determined to be owners the next time we returned, but money was tight, and we needed a plan. Shortly after coming home to Sterling, we were fishing as a family on the Kenai River when I received a very interesting phone call from a very good friend, mission companion, and fishing partner Zach Murdoch, who decided to try solar sales that summer. He was a good salesman because we ended up changing our flights to California and putting school at BYU on hold for the next few months.</Typography>
                    </div> :
                    <div style={isMobile ? columnContainer : { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '92.4rem', marginTop: '13.6875rem' }}>
                        <div style={isMobile ? { display: 'flex', flexDirection: 'column-reverse', width: '100%' } : { display: 'flex', width: '92.4365rem', justifyContent: 'space-between' }}>
                            <div style={isMobile ? { display: 'flex', flexDirection: 'column', width: '100%' } : { display: 'flex', flexDirection: 'column', width: '61rem' }}>
                                <Typography fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.15rem', width: '90%', textAlign: 'center', marginBottom: '2rem' } : { fontSize: '1.875rem', marginBottom: '3.8125rem' }}>Again, we had a successful and beautiful season of catching salmon, but I had never missed someone more in my entire life and wished so badly we could be together on that beautiful beach. At this point, we started to catch wind of our dream as a family: to come out as a family to this peaceful place and provide for our needs. I came home as fast as I could, and we spent the next months in love with our new little family.</Typography>
                                <Typography fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.15rem', width: '90%', textAlign: 'center', marginBottom: '2rem' } : { fontSize: '1.875rem', marginBottom: '3.8125rem' }}>Later that year, in November, our long-awaited marriage visa to the United States was approved, and we made the trip to Utah! We are forever grateful for loving friends and family that helped us make a smooth landing, especially Dave and Brittney, who provided us with a roof and a job to begin our journey in the US. We lived in St. George for a time, then took a job at Snowbird, plowing snow and floating bills. Samy grew, the snow melted, and the salmon were on their way. We packed up and headed to the last frontier.</Typography>
                                <Typography fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.15rem', width: '90%', textAlign: 'center' } : { fontSize: '1.875rem' }}>First, we arrived in Sterling where I was raised and moved into my parents' basement. Even though Any and Samy could not come with </Typography>
                            </div>
                            <img alt='jake with fish' draggable='false' src={jakeWithFish} style={isMobile ? { width: '100%' } : { height: '45rem' }} />
                        </div>
                        <Typography fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.15rem', width: '90%', marginBottom: '2rem', textAlign: 'center' } : { fontSize: '1.875rem', width: '92.4365rem', marginBottom: '3.8125rem' }}>me to fish camp, it was still a relief to have them closer as I headed to western Alaska once again. This year I was promoted as a beach boss and captained the boat for the summer. After an extremely successful year and breaking the operations all-time record, I was able to invite my sweetheart and princess out to my favorite beach so they could experience it firsthand the last few days of the season.</Typography>
                        <Typography fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.15rem', width: '90%', marginBottom: '2rem', textAlign: 'center' } : { fontSize: '1.875rem', width: '92.4365rem', marginBottom: '3.8125rem' }}>We left that place determined to be owners the next time we returned, but money was tight, and we needed a plan. Shortly after coming home to Sterling, we were fishing as a family on the Kenai River when I received a very interesting phone call from a very good friend, mission companion, and fishing partner Zach Murdoch, who decided to try solar sales that summer. He was a good salesman because we ended up changing our flights to California and putting school at BYU on hold for the next few months.</Typography>
                    </div>}
                <div className='background' style={isMobile ? { width: '100%' } : { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <div style={isMobile ? { display: 'flex', flexDirection: 'column', width: '100%' } : { width: '92.1875rem' }}>
                        <div style={isMobile ? { display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' } : { display: 'flex', marginTop: '11.9375rem' }}>
                            <img alt='jack' draggable='false' src={jack} style={isMobile ? { width: '100%' } : { width: '29.3125rem', marginRight: '7.25rem' }} />
                            <Typography fontSize='1.875rem' fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.15rem', width: '90%', textAlign: 'center', marginTop: '4rem', marginBottom: '4rem' } : { fontSize: '1.875rem' }}>A few months turned into a few years. We sold a lot of Solar and built teams, and more importantly, we kept building the family. Jack Helaman Meyer arrived on September 24th, 2020, shortly after our rental home was burned to the ground in a classic California wildfire. Our new baby boy only felt the fire of our dream to one day be fishing as a family. By 2022, we had built up a team of over 100 solar salesmen and golden memories that numbers cannot count. Sales taught us so much about life, about each other, finances, investing teamwork, and especially meeting goals. I was blessed enough to hire a life coach named Kevin Hall who helped me design our life the way we wanted it as a family. The most impactful was the creation of our family dream board that was peppered with Salmon and Alaska.</Typography>
                        </div>
                        <div style={isMobile ? { display: 'flex', flexDirection: 'column-reverse', width: '100%', alignItems: 'center' } : { display: 'flex', marginTop: '14.9375rem' }}>
                            <Typography fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.15rem', width: '90%', textAlign: 'center', marginTop: '4rem', marginBottom: '4rem' } : { fontSize: '1.875rem', marginRight: '7.25rem' }}>Our family got a little bit bigger as we welcomed our precious Snowy Aurora on August 5th, 2022, just the day after Any's birthday. Another gorgeous little princess joined us on our journey, and she has been such a smiling light and a blessing to our family. Samy was growing up and almost ready for kindergarten. Smack dead center of our board was the dream of our home where we wanted to build our definition of Zion: Perry, Utah. After 5 years of doing sales, we finally checked the house off the list and made the jump to our home that Any and I custom designed with much help from our cousin Breanna Meyer and of course, the best custom home builder of all time, Blake Terry.</Typography>
                            <img alt='snowy' draggable='false' src={snowy} style={isMobile ? { width: '100%' } : { height: '30rem', alignSelf: 'center' }} />
                        </div>
                        <div style={isMobile ? { display: 'flex', flexDirection: 'column', width: '100% ', alignItems: 'center' } : { display: 'flex', marginTop: '14.9375rem', marginBottom: '11.1875rem' }}>
                            <img alt='ocean' draggable='false' src={ocean} style={isMobile ? { width: '100%' } : { width: '29.3125rem', marginRight: '7.25rem' }} />
                            <Typography fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.15rem', width: '90%', textAlign: 'center', marginTop: '4rem', marginBottom: '4rem' } : { fontSize: '1.875rem' }}>Almost a year after moving in, we were pleasantly surprised with our grand finale, a little girl named Ocean Wendy Meyer, born on Oct 21st, 2023. Any did a completely natural home birth after a c-section, and it has been the coolest thing we have ever done. Ocean has brought such a peaceful and bright spirit to our home. Now here we are with four kids, five years old and under, ready to jump headfirst into our family dream. We have officially dumped our life savings into the same commercial salmon fishing business I worked at. We want to share this dream with the world, starting with you here in Utah. This is by far the freshest salmon I have ever seen here in Utah, and you can really taste it. We will forever be grateful for your support on our journey if you would like a piece of the freshest wildest salmon from our nets to your door.</Typography>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>}
        </>
    );
}