import axios from 'axios';
import Cookies from 'js-cookie'

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const getReservations = async () => {
  return await axios
    .get(
      `${API_URL}/reservations`, {
        headers: {
          'Authorization': Cookies.get('idToken'),
        }
      }
    )
    .then(response => {
      return response.data
    })
    .catch(err => {
      return err
    })
  }