import { CognitoUserPool } from "amazon-cognito-identity-js";

const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

const poolData = {
    UserPoolId: USER_POOL_ID,
    ClientId: CLIENT_ID,
}

const cognitoUserPool = new CognitoUserPool(poolData);
export default cognitoUserPool;