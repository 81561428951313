import React, { useState } from "react";
import { TextField, Button, Typography } from '@mui/material';
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js"
import UserPool from "./UserPool";
import Cookies from 'js-cookie'

const SECURE = process.env.REACT_APP_SECURE === 'false' ? false : true;

export default function Login(props) {
    const { setIsLoggedIn } = props;
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [isPasswordValid, setIsPasswordValid] = useState(true)
    const [isEmailValid, setIsEmailValid] = useState(true)
    const [loginErrorMessage, setLoginErrorMessage] = useState('')
    const [userData, setUserData] = useState('')
    const [user, setUser] = useState('')
    const [isNewPasswordRequired, setIsNewPasswordIsRequired] = useState(false)
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordVerity, setNewPasswordVerify] = useState('')
    const [newPasswordsMatch, setNewPasswordsMatch] = useState(true)
    const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState('')

    const handleEmailChange = (value) => {
        const isValid = value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g);
        setEmail(value)
        setIsEmailValid(isValid)
    }

    const handlePasswordChange = (value) => {
        const isValid = value.length > 0
        setPassword(value)
        setIsPasswordValid(isValid)
    }

    const handleLogin = () => {
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool,
        })

        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        })

        user.authenticateUser(authDetails, {
            onSuccess: (data) => {
                setLoginErrorMessage('')
                setEmail('')
                setPassword('')
                Cookies.set('idToken', data.getIdToken().getJwtToken(), { expires: 1, secure: SECURE, sameSite: 'strict' })
                Cookies.set('accessToken', data.getAccessToken().getJwtToken(), { expires: 1, secure: SECURE, sameSite: 'strict' })
                Cookies.set('refreshToken', data.getRefreshToken().getToken(), { expires: 1, secure: SECURE, sameSite: 'strict' })
                setIsLoggedIn(true)
            },
            onFailure: (err) => {
                setLoginErrorMessage(err.message)
            },
            newPasswordRequired: (data) => {
                delete data.email_verified
                delete data.email
                setLoginErrorMessage('')
                setUser(user)
                setUserData(data)
                setIsNewPasswordIsRequired(true)
                setEmail('')
                setPassword('')
            }
        });
    }
    const handleNewPassowrdChange = currentNewPassword => {
        setNewPassword(currentNewPassword)
    }

    const handleNewPassowrdVerifyChange = currentNewPassword => {
        setNewPasswordVerify(currentNewPassword)
        if (currentNewPassword !== newPassword) {
            setNewPasswordsMatch(false)
        } else {
            setNewPasswordsMatch(true)
        }
    }

    const newPasswordSubmit = () => {
        const newPasswordUser = user
        const newPasswordUserData = userData
        newPasswordUser.completeNewPasswordChallenge(newPassword, newPasswordUserData, {
            onSuccess: () => {
                setIsNewPasswordIsRequired(false)
                setNewPassword('')
                setNewPasswordVerify('')
                setLoginErrorMessage('')
                setNewPasswordsMatch(false)
                setNewPasswordErrorMessage('')
            },
            onFailure: (err) => {
                setNewPasswordErrorMessage(err.message)
            },

        })
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            {isNewPasswordRequired ?
                <div style={{ display: 'flex', flexDirection: 'column', height: '28rem', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography fontFamily='DM Serif Display' fontSize='1.5rem' marginBottom='2rem'>Please enter a new password</Typography>
                        <TextField
                            id="outlined-basic-password"
                            error={!newPasswordsMatch}
                            type='password'
                            label="Password"
                            variant="outlined"
                            value={newPassword}
                            style={{ width: '25rem', marginBottom: '2rem' }}
                            onChange={(event) => handleNewPassowrdChange(event.target.value)}
                        />
                        <TextField
                            id="outlined-basic-password-2"
                            error={!newPasswordsMatch}
                            type='password'
                            label="Verify Password"
                            variant="outlined"
                            value={newPasswordVerity}
                            style={{ width: '25rem', marginBottom: '0.25rem' }}
                            onChange={(event) => handleNewPassowrdVerifyChange(event.target.value)}
                        />
                        {!newPasswordsMatch && <Typography fontFamily='DM Serif Display' color='red'>Passwords don't match</Typography>}
                        {newPasswordErrorMessage && <Typography fontFamily='DM Serif Display' color='red'>{newPasswordErrorMessage}</Typography>}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Button variant='contained' disabled={!newPasswordsMatch} style={{ width: '25rem', height: '3.5rem', fontSize: '2rem', fontFamily: 'DM Serif Display', marginBottom: '1rem' }} onClick={() => newPasswordSubmit()}>Update Password</Button>

                        <Typography fontFamily='DM Serif Display' color='red' >Must be 8 or more characters long</Typography>
                        <Typography fontFamily='DM Serif Display' color='red' >Contains at least 1 number</Typography>
                        <Typography fontFamily='DM Serif Display' color='red' >Conatains at least 1 special charaacter</Typography>
                        <Typography fontFamily='DM Serif Display' color='red' >Contains at least 1 uppercase letter</Typography>
                        <Typography fontFamily='DM Serif Display' color='red' >Contains at least 1 lowercase letter</Typography>
                    </div>
                </div> :
                <div style={{ display: 'flex', flexDirection: 'column', height: '19rem', alignItems: 'center' }}>
                    <TextField
                        id="outlined-basic-email"
                        error={!isEmailValid}
                        label="Email"
                        variant="outlined"
                        value={email}
                        style={{ width: '25rem', marginBottom: '2rem' }}
                        onChange={(event) => handleEmailChange(event.target.value)}
                    />
                    <TextField
                        id="outlined-basic-password"
                        error={!isPasswordValid}
                        type='password'
                        label="Password"
                        variant="outlined"
                        value={password}
                        style={{ width: '25rem', marginBottom: '2rem' }}
                        onChange={(event) => handlePasswordChange(event.target.value)}
                    />
                    <Button variant='contained' style={{ width: '25rem', height: '3.5rem', fontSize: '2rem', fontFamily: 'DM Serif Display', borderRadius: 0, backgroundColor: '#5786a2' }} onClick={() => handleLogin()}>Login</Button>
                    {loginErrorMessage && <Typography color='red' fontFamily='DM Serif Display' marginTop='1.5rem'>{loginErrorMessage}</Typography>}
                </div>}
        </div>
    )
}

