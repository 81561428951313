import axios from 'axios';
const API_URL = process.env.REACT_APP_API_BASE_URL;

export const createReservation = async reservation => { 
  return await axios
    .post(
        `${API_URL}/reservation`, reservation
    )
    .then(response => {
      return response.status
    })
    .catch(err => {
      return err
    })
}