import React, { useState } from 'react';
import { TableFooter, TableBody, Table, TablePagination, Box, IconButton, tableCellClasses } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count=0, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#FA8072',
        color: 'black',
        fontWeight: 'bold',
        fontSize: '1.1rem'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '0.9rem',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function ReservationTable(props) {
    const {reservations} = props;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - reservations.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    return (
        <Box sx={{width: '90%', maxWidth: '90rem', marginBottom: '10rem'}}>
            <TableContainer component={Paper}>
                <Table aria-label="reservation table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell >First Name</StyledTableCell>
                            <StyledTableCell >Last Name</StyledTableCell>
                            <StyledTableCell >Email</StyledTableCell>
                            <StyledTableCell >Phone #</StyledTableCell>
                            <StyledTableCell >Address</StyledTableCell>
                            <StyledTableCell >Lbs</StyledTableCell>
                            <StyledTableCell >Price</StyledTableCell>
                            <StyledTableCell >Heard About Us</StyledTableCell>
                            <StyledTableCell >Reserved</StyledTableCell>
                            <StyledTableCell >Last Updated</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0 && reservations.length > 0
                            ? reservations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : ['']
                        ).map((row, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell>
                                    {row.firstName}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.lastName}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.email}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.phoneNum}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.address ? row.address : ""}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.lbs}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.price ? "$" + row.price : ""}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.hearAboutUs ? row.hearAboutUs : ""}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.created}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.lastUpdated}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53.5 * emptyRows }}>
                                <TableCell colSpan={9} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10]}
                                colSpan={9}
                                count={reservations.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    );
}