import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, Slider, styled, alpha, Select, MenuItem } from '@mui/material';
import salmon from '../assets/reserve-salmon.jpg'
import salmon2 from '../assets/reserve-salmon-2.png'
import scale from '../assets/scale.png'
import dollar from '../assets/dollar.png'
import useMediaQuery from '@mui/material/useMediaQuery';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import cacheImages from '../services/CacheImags';
import './ReserveYourSalmon'
import { createReservation } from '../services/Reserve';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "@fontsource-variable/dm-sans"
import "@fontsource/dm-serif-display"

const CustomSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-thumb': {
        borderRadius: '0.0625rem',
        width: '0.25rem',
        height: '2rem',
        color: '#5686A1'
    },
    '& .MuiSlider-rail': {
        color: '#5686A1'
    },
    '& .MuiSlider-track': {
        color: '#5686A1'
    }
}))

const CustomTextField = styled(TextField, { shouldForwardProp: (prop) => prop !== 'isMobile' })(({ isMobile }) => ({
    width: isMobile ? '90%' : '100%',
    maxWidth: '41.06675rem',
    marginRight: '1rem',
    marginLeft: '1rem',
    marginBottom: isMobile ? '2rem' : '4.1875rem',
    "& .MuiOutlinedInput-notchedOutline": {
        fontSize: isMobile ? '' : '1.5rem',
        fontFamily: 'DM Sans Variable',
    }
}))

export default function ReserveYourSalmon() {
    const isMobile = useMediaQuery('(max-width:1110px)');
    const freeDeliveryWeight = 10;
    const [fName, setFName] = useState('');
    const [isFNameValid, setIsFNameValid] = useState(true)
    const [lName, setLName] = useState('');
    const [isLNameValid, setIsLNameValid] = useState(true)
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true)
    const [number, setNumber] = useState('');
    const [isNumberValid, setIsNumberValid] = useState(true)
    const [lbs, setlbs] = useState('');
    const [isLbsValid, setIsLbsValid] = useState(true)
    const [price, setPrice] = useState('');
    const [address, setAddress] = useState('');
    const [isAddressValid, setIsAddressValid] = useState(true)
    const [city, setCity] = useState('');
    const [isCityValid, setIsCityValid] = useState(true)
    const [zipCode, setZipCode] = useState('');
    const [isZipCodeValid, setIsZipCodeValid] = useState(true)
    const [currentSliderValue, setCurrentSliderValue] = useState(1)
    const [currentSliderPricePerLb, setCurrentSliderPricePerLb] = useState(18)
    const [currentSliderPretaxTotal, setCurrentSliderPretaxTotal] = useState(0)
    const [isLoading, setIsLoading] = useState(false);
    const [hearAboutUs, setHearAboutUs] = useState('');
    const [hearAboutUsOther, setHearAboutUsOther] = useState('');
    const [isHearAboutUsValid, setIsHearAboutUsValid] = useState(true);

    const row = {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '1rem',
    }
    const hearAboutUsOtherRow = {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '1rem',
        width: '50%',
    }
    const column = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }

    const container = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }

    const mobileContainer = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }

    const priceWeightContainer = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '95%',
        maxWidth: '92.5rem',
        height: '46.125rem',
    }

    const priceWeightMobileContainer = {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '5rem',
        marginBottom: '4rem',
    }

    const priceTableText = {
        fontSize: '1.875rem',
        fontFamily: 'DM Sans Variable'
    }

    const priceTableMobileText = {
        fontSize: '1rem',
        fontFamily: 'DM Sans Variable'
    }

    const backgroundCircleSalmon = {
        borderRadius: '39.3125rem',
        width: '39.3125rem',
        height: '39.3125rem',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: alpha('#5786a2', 0.1)
    }

    const backgroundCircleSalmonMobile = {
        borderRadius: '17rem',
        width: '17rem',
        height: '17rem',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: alpha('#5786a2', 0.1),
        alignSelf: 'center',
        marginTop: '4rem'
    }

    const salmonPic = {
        width: '34.375em'
    }

    const salmonPicMobile = {
        width: '15rem',
        alignSelf: 'center',
    }

    const reservationContainer = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
        marginTop: '9rem',
        marginBottom: '9rem',
        width: '90%',
        maxWidth: '92.5rem'
    }

    const reservationMobileContainer = {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '3.5rem',
        marginBottom: '4rem',
        width: '100%',
    }

    const bottomReserveOrderButton = {
        alignSelf: 'center',
        backgroundColor: '#5786a2',
        fontSize: '2.67rem',
        fontFamily: 'DM Serif Display',
        width: '30rem',
        height: '7',
        marginTop: '5rem',
        borderRadius: 0,
        marginBottom: '2.25rem',
        '&:hover': {
            backgroundColor: alpha('#5786a2', 0.75)
        }
    }

    const bottomReserveOrderMobileButton = {
        alignSelf: 'center',
        backgroundColor: '#5786a2',
        fontSize: '1.5rem',
        fontFamily: 'DM Serif Display',
        width: '20rem',
        height: '7',
        marginTop: '1rem',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: alpha('#5786a2', 0.75)
        }
    }

    useEffect(() => {
        const preloadList = [salmon, salmon2]
        cacheImages(preloadList, setIsLoading)
    }, [])

    useEffect(() => {
        if (lbs < freeDeliveryWeight) {
            setAddress('')
            setCity('')
            setZipCode('')
        }
    }, [lbs])

    useEffect(() => {
        if (currentSliderValue >= 100) {
            setCurrentSliderPricePerLb(15)
        } else if (currentSliderValue >= 40) {
            setCurrentSliderPricePerLb(16)
        }
        else if (currentSliderValue >= 10) {
            setCurrentSliderPricePerLb(17)
        } else {
            setCurrentSliderPricePerLb(18)
        }
        setCurrentSliderPretaxTotal(currentSliderValue * currentSliderPricePerLb)
    }, [currentSliderValue, currentSliderPricePerLb])

    const handleAmountChange = (value) => {
        const sanitizedInput = value.replace(/[^0-9]/g, '');
        if (value.length === 0) {
            setIsLbsValid(false)
        } else {
            setIsLbsValid(true)
        }
        setlbs(sanitizedInput)
        let currentValue = sanitizedInput

        if (currentValue < freeDeliveryWeight) {
            setAddress('')
            setIsAddressValid(true)
            setCity('')
            setIsCityValid(true)
            setZipCode('')
            setIsZipCodeValid(true)
        }
        if (currentValue >= 100) {
            currentValue = 15 * currentValue
            setPrice(currentValue)
        }
        else if (currentValue >= 40) {
            currentValue = 16 * currentValue
            setPrice(currentValue)
        }
        else if (currentValue >= 10) {
            currentValue = 17 * currentValue
            setPrice(currentValue)
        }
        else if (currentValue === 0) {
            setPrice(currentValue)
        }
        else {
            currentValue = 18 * currentValue
            setPrice(currentValue)
        }
    }

    const handlePhoneNumberChange = (value) => {
        let sanitizedInput = value.replace(/[^0-9]/g, '');
        if (sanitizedInput.length >= 4) {
            sanitizedInput = `(${sanitizedInput.slice(0, 3)}) ${sanitizedInput.slice(3)}`
            if (sanitizedInput.length >= 10) {
                sanitizedInput = `${sanitizedInput.slice(0, 9)}-${sanitizedInput.slice(9)}`
            }
        }
        if (sanitizedInput.length > 14) {
            sanitizedInput = sanitizedInput.slice(0, 14)
        }
        setNumber(sanitizedInput)
        if (sanitizedInput.length < 14) {
            setIsNumberValid(false)
        } else {
            setIsNumberValid(true)
        }
    }

    const handleFNameChange = (value) => {
        const match = value.match(/^([a-zA-Z 'ñáéíóúü]*)$/g);
        setFName(value)
        if (value.length === 0) {
            setIsFNameValid(false)
        } else {
            match ? setIsFNameValid(true) : setIsFNameValid(false)
        }
    }

    const handleLNameChange = (value) => {
        const match = value.match(/^([a-zA-Z 'ñáéíóúü]*)$/g);
        setLName(value)
        if (value.length === 0) {
            setIsLNameValid(false)
        } else {
            match ? setIsLNameValid(true) : setIsLNameValid(false)
        }
    }

    const handleEmailChange = (value) => {
        const match = value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g);
        setEmail(value)
        match ? setIsEmailValid(true) : setIsEmailValid(false)
    }

    const handleAddressChange = (value) => {
        const match = value.match(/^([0-9a-zA-Z ]*)$/g);
        setAddress(value)
        if (value.length === 0) {
            setIsAddressValid(false)
        } else {
            match ? setIsAddressValid(true) : setIsAddressValid(false)
        }

    }

    const handleCityChange = (value) => {
        const match = value.match(/^([a-zA-Z]*)$/g);
        setCity(value)
        if (value.length === 0) {
            setIsCityValid(false)
        } else {
            match ? setIsCityValid(true) : setIsCityValid(false)
        }

    }

    const handleZipCodeChange = (value) => {
        const match = value.match(/^([0-9]*-*[0-9]*)$/g);
        setZipCode(value)
        if (value.length < 5) {
            setIsZipCodeValid(false)
        } else {
            match ? setIsZipCodeValid(true) : setIsZipCodeValid(false)
        }

    }

    const handleHearAboutUsChange = value => {
        if(value !== 'Other'){
            setHearAboutUsOther('')
        }
        setIsHearAboutUsValid(true)
        setHearAboutUs(value)
    }

    const handleHearAboutUsOtherChange = value => {
        setHearAboutUsOther(value)
    }

    const handleSubmitReservation = async () => {
        if (isFNameValid &&
            fName.length > 0 &&
            isLNameValid &&
            lName.length > 0 &&
            isEmailValid &&
            email.length > 0 &&
            isNumberValid &&
            number.length > 0 &&
            isLbsValid &&
            lbs.length > 0 &&
            isHearAboutUsValid &&
            hearAboutUs !== '' &&
            (lbs < 40 || (address.length > 0 && isAddressValid && city.length > 0 && isCityValid && zipCode.length >= 5 && isZipCodeValid))) {
            const reservation = {
                'firstName': fName,
                'lastName': lName,
                'email': email,
                'phoneNum': number.toString(),
                'lbs': lbs.toString(),
                'price': price.toString(),
                'address': address && city && zipCode ? `${address}, ${city}, UT ${zipCode}` : '',
                'hearAboutUs': hearAboutUs === 'Other' ? hearAboutUs + ' - ' + hearAboutUsOther : hearAboutUs,
            }
            const reservationStatus = await createReservation(reservation);
            if (reservationStatus === 200) {
                toast.success('Reservation Successful', { theme: 'colored' })
                setFName('')
                setLName('')
                setEmail('')
                setNumber('')
                setlbs('')
                setPrice('')
                setAddress('')
                setCity('')
                setZipCode('')
                setHearAboutUs('')
            }
            else {
                toast.error('Reservation Failed', { theme: 'colored' })
            }
        } else {
            toast.error('Please complete required fields', { theme: 'colored' })
            if (fName.length === 0) {
                setIsFNameValid(false)
            }
            if (lName.length === 0) {
                setIsLNameValid(false)
            }
            if (email.length === 0) {
                setIsEmailValid(false)
            }
            if (number.length === 0) {
                setIsNumberValid(false)
            }
            if (lbs.length === 0) {
                setIsLbsValid(false)
            }
            if (address.length === 0) {
                setIsAddressValid(false)
            }
            if (city.length === 0) {
                setIsCityValid(false)
            }
            if (zipCode.length === 0) {
                setIsZipCodeValid(false)
            }
            if (hearAboutUs === ''){
                setIsHearAboutUsValid(false)
            }
        }
    }
    return (
        <div style={isMobile ? mobileContainer : container}>
            <ToastContainer />
            {!isLoading &&
                <>
                    <NavBar currentBackgroundColor='' color='white' position='absolute' bear='white' />
                    <img alt='salmon' draggable='false' src={salmon} style={{ width: '100%', }} />
                    <Typography color='white' fontFamily='DM Serif Display' style={isMobile ? { alignSelf: 'center', marginTop: '8rem', position: 'absolute', fontSize: '2.25rem' } : { alignSelf: 'normal', marginTop: '15rem', marginLeft: '14rem', position: 'absolute', fontSize: '4.5rem' }}>Reserve Your Salmon</Typography>
                    <div style={isMobile ? priceWeightMobileContainer : priceWeightContainer}>
                        <div style={isMobile ? { display: 'flex' } : { display: 'flex', alignItems: 'center', width: '100%', maxWidth: '40rem', justifyContent: 'space-between' }}>
                            <div style={isMobile ? { marginRight: '1rem' } : {}}>
                                <div>
                                    <img alt='scale' draggable='false' src={scale} style={isMobile ? { width: '2.5rem' } : { width: '3.625rem' }} />
                                    <Typography fontSize={isMobile ? '1.5rem' : '2.5rem'} color='#5786a2' fontFamily='DM Serif Display' >Weight (lbs)</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ textAlign: 'end' }}>
                                        <Typography style={isMobile ? priceTableMobileText : priceTableText}>1</Typography>
                                        <Typography style={isMobile ? priceTableMobileText : priceTableText}>10</Typography>
                                        <Typography style={isMobile ? priceTableMobileText : priceTableText}>40</Typography>
                                    </div>
                                    <div style={{ marginLeft: '1rem' }}>
                                        <Typography style={isMobile ? priceTableMobileText : priceTableText}>-</Typography>
                                        <Typography style={isMobile ? priceTableMobileText : priceTableText}>-</Typography>
                                        <Typography style={isMobile ? priceTableMobileText : priceTableText}>-</Typography>
                                    </div>
                                    <div style={{ marginLeft: '1rem' }}>
                                        <Typography style={isMobile ? priceTableMobileText : priceTableText}>9</Typography>
                                        <Typography style={isMobile ? priceTableMobileText : priceTableText}>39</Typography>
                                        <Typography style={isMobile ? priceTableMobileText : priceTableText}>99</Typography>
                                        <Typography style={isMobile ? priceTableMobileText : priceTableText}>100+</Typography>
                                    </div>
                                </div>
                            </div>
                            <div style={isMobile ? { marginLeft: '1rem' } : { marginLeft: '1rem' }}>
                                <div>
                                    <img alt='dollar' draggable='false' src={dollar} style={isMobile ? { height: '2.5rem', marginLeft: '-1rem', marginBottom: '.05rem' } : { height: '3.625rem', marginLeft: '-1.25rem' }} />
                                    <Typography fontSize={isMobile ? "1.5rem" : '2.5rem'} color='#5786a2' fontFamily='DM Serif Display' >Prices</Typography>
                                </div>

                                <Typography style={isMobile ? priceTableMobileText : priceTableText}>$18/lb</Typography>
                                <Typography style={isMobile ? priceTableMobileText : priceTableText}>$17/lb free delivery</Typography>
                                <Typography style={isMobile ? priceTableMobileText : priceTableText}>$16/lb free delivery</Typography>
                                <Typography style={isMobile ? priceTableMobileText : priceTableText}>$15/lb free delivery</Typography>
                            </div>
                        </div>
                        <div style={isMobile ? backgroundCircleSalmonMobile : backgroundCircleSalmon}>
                            <img alt='salmon 2' draggable='false' src={salmon2} style={isMobile ? salmonPicMobile : salmonPic} />
                        </div>
                    </div>
                    <div className='background' style={isMobile ? { display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '20rem' } : { display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '30rem' }}>
                        <div style={isMobile ? { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%' } : { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '54.625rem' }}>
                            <Typography color='#5686A1' fontFamily='DM Serif Display' style={isMobile ? { fontSize: '1.75rem', marginBottom: '3.5rem' } : { fontSize: '2.5rem', marginBottom: '5.1875rem' }} >Cost Estimator (Pretax)</Typography>
                            <div style={isMobile ? { marginBottom: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center' } : { marginBottom: '4.875rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={isMobile ? { display: 'flex', border: '0.15rem solid #5686A1', paddingLeft: '0.75rem', width: '5rem' } : { display: 'flex', border: '0.15rem solid #5686A1', paddingLeft: '1rem', width: '12rem' }}>
                                    <Typography color='#5686A1' fontFamily='DM Serif Display' style={isMobile ? { fontSize: '1.75rem' } : { fontSize: '4.4125rem' }}>{currentSliderValue} lb</Typography>
                                </div>
                                <Typography fontFamily='DM Sans Variable' style={isMobile ? { marginLeft: '0.25rem', marginRight: '0.25rem', fontSize: '1.75rem' } : { marginLeft: '0.75rem', marginRight: '0.75rem', fontSize: '3.4349375rem' }}>X</Typography>
                                <div style={isMobile ? { display: 'flex', justifyContent: 'center', border: '0.15rem solid #5686A1', width: '5.25rem' } : { display: 'flex', justifyContent: 'center', border: '0.15rem solid #5686A1', width: '14rem' }}>
                                    <Typography color='#5686A1' fontFamily='DM Serif Display' style={isMobile ? { fontSize: '1.75rem' } : { fontSize: '4.4125rem' }}>${currentSliderPricePerLb}/lb</Typography>
                                </div>
                                <Typography fontFamily='DM Sans Variable' style={isMobile ? { marginLeft: '0.25rem', marginRight: '0.25rem', fontSize: '1.75rem' } : { marginLeft: '0.75rem', marginRight: '0.75rem', fontSize: '3.4349375rem' }}>=</Typography>
                                <div style={isMobile ? { display: 'flex', alignItems: 'center', border: '0.15rem solid #5686A1', paddingLeft: '0.75rem', width: '5rem' } : { display: 'flex', alignItems: 'center', border: '0.15rem solid #5686A1', paddingLeft: '1rem', width: '12rem' }}>
                                    <Typography color='#5686A1' fontFamily='DM Serif Display' style={isMobile ? { fontSize: '1.75rem' } : { fontSize: '4.4125rem' }}>${currentSliderPretaxTotal}</Typography>
                                </div>
                            </div>
                            <CustomSlider
                                aria-label="lbs"
                                defaultValue={1}
                                valueLabelDisplay="auto"
                                step={1}
                                min={1}
                                max={100}
                                onChange={(e, value) => setCurrentSliderValue(value)}
                            />
                        </div>
                    </div>
                    <div style={isMobile ? reservationMobileContainer : reservationContainer}>
                        <Typography color='#5686A1' alignSelf='center' fontFamily='DM Serif Display' style={isMobile ? { fontSize: '1.75rem', marginBottom: '3.5rem' } : { fontSize: '2.5rem', marginBottom: '5rem' }}>Place Reservation</Typography>
                        <Typography fontFamily='DM Sans Variable' width='90%' textAlign='center' marginBottom='2rem' alignSelf='center' style={isMobile ? { fontSize: '1.15rem' } : { fontSize: '1.875rem' }}>We currently have plenty of fresh salmon in stock from this 2024 season. Within 24-48 hours you should receive a confirmation of your risk free order. Payment will be accepted by Cash, Venmo or Credit Card (3% fee) when we hand you the salmon. Unless you were told otherwise, orders smaller than {freeDeliveryWeight} lbs will need to be picked up in Perry, UT. Any orders over {freeDeliveryWeight} lbs qualify for free delivery in Utah.</Typography>
                        <div style={isMobile ? column : row}>
                            <CustomTextField
                                id="outlined-basic-first-name"
                                error={!isFNameValid}
                                required
                                label="First Name"
                                variant="outlined"
                                InputLabelProps={{
                                    style: isMobile ? {
                                        fontFamily: 'DM Sans Variable'
                                    } : {
                                        fontSize: '1.5rem',
                                        fontFamily: 'DM Sans Variable',
                                    }
                                }}
                                inputProps={{ style: isMobile ? { fontFamily: 'DM Sans Variable' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable' } }}
                                value={fName}
                                onChange={(event) => handleFNameChange(event.target.value)}
                                isMobile={isMobile}
                            />
                            <CustomTextField
                                id="outlined-basic-last-name"
                                error={!isLNameValid}
                                required
                                label="Last Name"
                                variant="outlined"
                                InputLabelProps={{
                                    style: isMobile ? {
                                        fontFamily: 'DM Sans Variable'
                                    } : {
                                        fontSize: '1.5rem',
                                        fontFamily: 'DM Sans Variable',
                                    }
                                }}
                                inputProps={{ style: isMobile ? { fontFamily: 'DM Sans Variable' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable' } }}
                                value={lName}
                                onChange={(event) => handleLNameChange(event.target.value)}
                                isMobile={isMobile}
                            />
                        </div>
                        <div style={isMobile ? column : row}>
                            <CustomTextField
                                id="outlined-basic-first-name"
                                error={!isEmailValid}
                                required
                                label="Email"
                                variant="outlined"
                                type='email'
                                InputLabelProps={{ style: isMobile ? { fontFamily: 'DM Sans Variable' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable' } }}
                                inputProps={{ style: isMobile ? { fontFamily: 'DM Sans Variable' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable' } }}
                                value={email}
                                onChange={(event) => handleEmailChange(event.target.value)}
                                isMobile={isMobile}
                            />
                            <CustomTextField
                                id="outlined-basic-last-name"
                                error={!isNumberValid}
                                required
                                label="Phone Number"
                                variant="outlined"
                                InputLabelProps={{ style: isMobile ? { fontFamily: 'DM Sans Variable' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable' } }}
                                inputProps={{ style: isMobile ? { fontFamily: 'DM Sans Variable' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable' } }}
                                value={number}
                                onChange={(event) => handlePhoneNumberChange(event.target.value)}
                                isMobile={isMobile}
                            />
                        </div>
                        <div style={isMobile ? column : row}>
                            <CustomTextField
                                id="outlined-basic"
                                error={!isLbsValid}
                                required
                                label="Amount (lbs)"
                                variant="outlined"
                                InputLabelProps={{ style: isMobile ? { fontFamily: 'DM Sans Variable' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable' } }}
                                inputProps={{ style: isMobile ? { fontFamily: 'DM Sans Variable' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable' } }}
                                value={lbs} onChange={(event) => handleAmountChange(event.target.value)}
                                isMobile={isMobile}
                            />
                            <CustomTextField
                                disabled
                                id="outlined-disabled"
                                label="Pretax Total"
                                variant="outlined"
                                InputLabelProps={{ style: isMobile ? { fontFamily: 'DM Sans Variable' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable' } }}
                                inputProps={{ style: isMobile ? { fontFamily: 'DM Sans Variable' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable' } }}
                                value={"$ " + price}
                                isMobile={isMobile}
                            />
                        </div>
                        
                        <div style={isMobile ? column : (hearAboutUs === 'Other' ? row : hearAboutUsOtherRow)}>
                            <Select
                                displayEmpty
                                style={isMobile ? { fontFamily: 'DM Sans Variable', width: '90%', marginBottom: '2rem' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable', width: '100%', marginRight: '1rem', marginLeft: '1rem', marginBottom: '4.1875rem', }}
                                value={hearAboutUs}
                                error={!isHearAboutUsValid}
                                onChange={(event) => handleHearAboutUsChange(event.target.value)}
                            >
                                <MenuItem style={!isHearAboutUsValid ? {color: 'red'} : {color: 'red'}} disabled value="">
                                    How did you hear about us?
                                </MenuItem>
                                <MenuItem style={isMobile ? {} : { fontSize: '1.5rem' }} value="Facebook">Facebook</MenuItem>
                                <MenuItem style={isMobile ? {} : { fontSize: '1.5rem' }} value="Friend">Instragram</MenuItem>
                                <MenuItem style={isMobile ? {} : { fontSize: '1.5rem' }} value="Friends/Family">Friends/Family</MenuItem>
                                <MenuItem style={isMobile ? {} : { fontSize: '1.5rem' }} value="Business Card">Business Card</MenuItem>
                                <MenuItem style={isMobile ? {} : { fontSize: '1.5rem' }} value="Road Sign">Road Sign</MenuItem>
                                <MenuItem style={isMobile ? {} : { fontSize: '1.5rem' }} value="Flyer">Flyer</MenuItem>
                                <MenuItem style={isMobile ? {} : { fontSize: '1.5rem' }} value="Other">Other</MenuItem>
                            </Select>
                            {hearAboutUs === 'Other' &&
                                <CustomTextField
                                    id="outlined-basic"
                                    label="Please Explain"
                                    variant="outlined"
                                    InputLabelProps={{ style: isMobile ? { fontFamily: 'DM Sans Variable' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable' } }}
                                    inputProps={{ style: isMobile ? { fontFamily: 'DM Sans Variable' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable' } }}
                                    value={hearAboutUsOther}
                                    onChange={(event) => handleHearAboutUsOtherChange(event.target.value)}
                                    isMobile={isMobile}
                                />}
                        </div>
                        {lbs >= freeDeliveryWeight && (
                            <div >
                                <Typography textAlign='center' marginBottom='3rem' fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.25rem' } : { fontSize: '1.875rem' }}>You qualify for delivery to a Utah address.</Typography>
                                <div style={isMobile ? column : row}>
                                    <CustomTextField
                                        id="outlined-basic"
                                        error={!isAddressValid}
                                        required
                                        label="Address"
                                        variant="outlined"
                                        InputLabelProps={{ style: isMobile ? { fontFamily: 'DM Sans Variable' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable' } }}
                                        inputProps={{ style: isMobile ? { fontFamily: 'DM Sans Variable' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable' } }}
                                        value={address} onChange={(event) => handleAddressChange(event.target.value)}
                                        isMobile={isMobile}
                                    />
                                    <CustomTextField
                                        id="outlined-disabled"
                                        error={!isCityValid}
                                        required
                                        label="City"
                                        variant="outlined"
                                        InputLabelProps={{ style: isMobile ? { fontFamily: 'DM Sans Variable' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable' } }}
                                        inputProps={{ style: isMobile ? { fontFamily: 'DM Sans Variable' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable' } }}
                                        value={city} onChange={(event) => handleCityChange(event.target.value)}
                                        isMobile={isMobile}
                                    />
                                </div>
                                <div style={isMobile ? column : row}>
                                    <CustomTextField
                                        disabled
                                        id="outlined-basic"
                                        label="State"
                                        variant="outlined"
                                        InputLabelProps={{ style: isMobile ? { fontFamily: 'DM Sans Variable' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable' } }}
                                        inputProps={{ style: isMobile ? { fontFamily: 'DM Sans Variable' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable' } }}
                                        value="UT"
                                        isMobile={isMobile}
                                    />
                                    <CustomTextField
                                        id="outlined-disabled"
                                        error={!isZipCodeValid}
                                        required
                                        label="Zip Code"
                                        variant="outlined"
                                        InputLabelProps={{ style: isMobile ? { fontFamily: 'DM Sans Variable' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable' } }}
                                        inputProps={{ style: isMobile ? { fontFamily: 'DM Sans Variable' } : { fontSize: '1.5rem', fontFamily: 'DM Sans Variable' } }}
                                        value={zipCode} onChange={(event) => handleZipCodeChange(event.target.value)}
                                        isMobile={isMobile}
                                    />
                                </div>
                            </div>
                        )}
                        <Button
                            variant='contained'
                            style={isMobile ? bottomReserveOrderMobileButton : bottomReserveOrderButton}
                            onClick={() => handleSubmitReservation()}>
                            Reserve Order
                        </Button>
                    </div>
                    <Footer />
                </>}
        </div>
    );
}