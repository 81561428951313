
const cacheImages = async (srcList, setIsLoading) => {
    const promises = await srcList.map((src) => {
        return new Promise(function (resolve, reject) {
            const img = new Image();
            img.src = src
            img.onload = resolve()
            img.onerror = reject()
        });
    });
    await Promise.all(promises);
    setIsLoading(false);
}

export default cacheImages;