import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home'
import OurStory from './pages/OurStory'
import ReserveYourSalmon from './pages/ReserveYourSalmon'
import Recipes from './pages/Recipes'
import NoPage from './pages/NoPage';
import Admin from './pages/Admin';
import ContactUs from './pages/ContactUs';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='OurStory' element={<OurStory />} />
      <Route path='ReserveYourSalmon' element={<ReserveYourSalmon />} />
      <Route path='Recipes' element={<Recipes />} />
      <Route path='ContactUs' element={<ContactUs />} />
      <Route path='Admin' element={<Admin />} />
      <Route path='*' element={<NoPage />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
