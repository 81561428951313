import React, { useEffect, useState } from 'react';
import logo from '../assets/logo-2.0-500px.png';
import protein from '../assets/benefits/protein.png'
import omega from '../assets/benefits/omega.png'
import b12 from '../assets/benefits/b12.png'
import fish from '../assets/benefits/fish.png'
import vitaminA from '../assets/benefits/a.png'
import vitaminD from '../assets/benefits/d.png'
import selenium from '../assets/benefits/selenium.png'
import family from '../assets/main-family.jpg'
import salmon from '../assets/salmon_bowl.jpg'
import lifeCycleSalmon from '../assets/life-cycle-salmon.jpg'
import { Button, Typography, alpha } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import cacheImages from '../services/CacheImags';
import "@fontsource-variable/dm-sans"
import "@fontsource/dm-serif-display"

export default function Home() {
    const isMobile = useMediaQuery('(max-width:1100px)');
    const [isLoading, setIsLoading] = useState(true);
    const mobileLogo = {
        position: 'absolute',
        marginTop: '15vw',
        marginLeft: '35vw',
        width: '40vw',
        height: '40vw',
    }
    const normalLogo = {
        marginTop: '12vw',
        marginLeft: '35vw',
        width: '40vw',
        height: '40vw',
        position: 'absolute',
    }

    useEffect(() => {
        const preloadList = [family, logo]
        cacheImages(preloadList, setIsLoading)
    }, [])

    const handleClick = () => {
        window['scrollTo']({top: 0, behavior: 'instant' }) 
    }

    return (
        <>
            {!isLoading && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <NavBar currentBackgroundColor='' color='black' position='absolute' />
                <img alt='family' draggable='false' src={family} style={{ width: '100%' }} />
                <img alt='logo' draggable='false' src={logo} style={isMobile ? mobileLogo : normalLogo} />
                <div style={isMobile ? { display: 'flex', flexDirection: 'column', width: '100%', marginTop: '3rem', alignItems: 'center' } : { display: 'flex', flexDirection: 'column', width: '90.62rem', marginTop: '25rem' }}>
                    <Typography color='#5786a2' fontFamily='DM Serif Display' style={isMobile ? { fontSize: '2.5rem' } : { fontSize: '4.5rem' }}>Why Our Salmon?</Typography>
                    <Typography fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.15rem', width: '90%', marginTop: '3.5rem', textAlign: 'center' } : { fontSize: '1.875rem', marginTop: '5.4735rem' }}>We live here in Perry, Utah. Each summer we travel to Alaska to fish for red salmon in the pristine waters of Bristol Bay. Our small family operation handpicks each fish from our own nets before they enter the Naknek River system. We deliver these healthy fish to certified professionals who clean, package, freeze and ship the fillets to Utah. These salmon fillets are a bright red color, rich in antioxidants and omega-3 fatty acids due to their healthy and diverse Pacific Ocean diet. We have chosen to leave the bones in our fillets because it helps to preserve the moisture and natural flavor of the fish. We believe your family deserves the, healthiest, Wild-Caught Alaska Salmon available in all of Utah.</Typography>
                </div>
                <Button
                    variant='contained'
                    component={RouterLink}
                    to="/ReserveYourSalmon"
                    onClick={() => handleClick()}
                    style={isMobile ?
                        {
                            backgroundColor: '#5786a2',
                            fontSize: '1.15rem',
                            fontFamily: 'DM Serif Display',
                            marginTop: '4rem',
                            width: '21rem',
                            borderRadius: 0,
                            '&:hover': {
                                backgroundColor: alpha('#5786a2', 0.75)
                            }
                        } : {
                            backgroundColor: '#5786a2',
                            fontSize: '2.67rem',
                            fontFamily: 'DM Serif Display',
                            marginTop: '10rem',
                            width: '35.51rem',
                            height: '7',
                            borderRadius: 0,
                            '&:hover': {
                                backgroundColor: alpha('#5786a2', 0.75)
                            }
                        }}>
                    Reserve Your Salmon
                </Button>
                <Typography fontFamily='DM Serif Display' color='#5786a2' style={isMobile ? { fontSize: '2rem', marginTop: '4rem' } : { fontSize: '4.5rem', marginTop: '12.75rem' }}>Benefits of wild salmon</Typography>
                <Typography fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.15rem', marginTop: '3rem', width: '90%', textAlign: 'center' } : { fontSize: '1.875rem', marginTop: '3rem' }}>The 6 benefits that wild salmon has for your body (properties of salmon):</Typography>
                <div style={isMobile ? { display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '4rem', width: '100%' } : { display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '9.375rem', width: '90rem' }}>
                    <div style={isMobile ? { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' } : { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <div style={isMobile ? { display: 'flex', flexDirection: 'column', width: '90%', } : { display: 'flex', flexDirection: 'column', width: '24.5rem' }}>
                            <div style={{ display: 'flex', alignItems: 'end' }}>
                                <Typography color='#5786a2' fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.75rem' } : { fontSize: '2.25rem' }}>Protein:</Typography>
                                <img alt='protein' draggable='false' src={protein} style={isMobile ? { width: '4rem', marginLeft: '3rem' } : { width: '7rem', marginLeft: '5.25rem' }} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
                                <div style={isMobile ? { width: '12rem', height: '0.075rem', backgroundColor: '#5786a2' } : { width: '18rem', height: '0.075rem', backgroundColor: '#5786a2' }} />
                                <div style={{ width: '0.5rem', height: '0.5rem', borderRadius: '0.5rem', backgroundColor: '#5786a2' }} />
                            </div>
                            <Typography fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.15rem', marginTop: '1.5rem', width: '90%' } : { fontSize: '1.875rem', marginTop: '2.3125rem' }}>Essential nutrient that helps build and maintain the body's cells. Salmon proteins are easily digestible, vital to our diet because they contain essential amino acids.</Typography>
                        </div>
                        <div style={isMobile ? { display: 'flex', flexDirection: 'column', width: '90%', marginTop: '3rem' } : { display: 'flex', flexDirection: 'column', justifyContent: 'end', width: '21.37rem', marginBottom: '-4rem', marginLeft: '7rem' }}>
                            <div style={{ display: 'flex', alignItems: 'end' }}>
                                <Typography color='#5786a2' fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.75rem' } : { fontSize: '2.25rem' }}>Omega-3:</Typography>
                                <img alt='omega' draggable='false' src={omega} style={isMobile ? { width: '4rem', marginLeft: '1rem' } : { width: '7rem', marginLeft: '3rem' }} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
                                <div style={isMobile ? { width: '12rem', height: '0.075rem', backgroundColor: '#5786a2' } : { width: '18rem', height: '0.075rem', backgroundColor: '#5786a2' }} />
                                <div style={{ width: '0.5rem', height: '0.5rem', borderRadius: '0.5rem', backgroundColor: '#5786a2' }} />
                            </div>
                            <Typography fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.15rem', marginTop: '1.5rem', width: '90%' } : { fontSize: '1.875rem', marginTop: '2.3125rem' }}>The first resource of the so-called "good fats", omega-3, which we need to eat and which is only found in fish, and especially in wild salmon.</Typography>
                        </div>
                        <div style={isMobile ? { display: 'flex', flexDirection: 'column', width: '90%', marginTop: '3rem' } : { display: 'flex', flexDirection: 'column', justifyContent: 'end', width: '24.5rem', marginBottom: '-17rem' }}>
                            <div style={{ display: 'flex', alignItems: 'end' }}>
                                <Typography color='#5786a2' fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.75rem' } : { fontSize: '2.25rem' }}>Vitamin B12:</Typography>
                                <img alt='b12' draggable='false' src={b12} style={isMobile ? { width: '4rem' } : { width: '7rem', marginLeft: '1rem' }} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
                                <div style={isMobile ? { width: '12rem', height: '0.075rem', backgroundColor: '#5786a2' } : { width: '18rem', height: '0.075rem', backgroundColor: '#5786a2' }} />
                                <div style={{ width: '0.5rem', height: '0.5rem', borderRadius: '0.5rem', backgroundColor: '#5786a2' }} />
                            </div>
                            <Typography fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.15rem', marginTop: '1.5rem', width: '90%' } : { fontSize: '1.875rem', marginTop: '2.3125rem' }}>Essential nutrient produced by blood cells and helping to keep nerve cells healthy.</Typography>
                        </div>
                    </div>
                    {isMobile ? <></> : <img alt='salmon' draggable='false' src={fish} style={{ width: '85rem' }} />}
                    <div style={isMobile ? { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' } : { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <div style={isMobile ? { display: 'flex', flexDirection: 'column', width: '90%', marginTop: '3rem' } : { display: 'flex', flexDirection: 'column', width: '24.5rem', marginTop: '-23rem' }}>
                            <div style={{ display: 'flex', alignItems: 'end' }}>
                                <Typography color='#5786a2' fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.75rem' } : { fontSize: '2.25rem' }}>Vitamin A:</Typography>
                                <img alt='Vitamin A' draggable='false' src={vitaminA} style={isMobile ? { width: '4.5rem', marginBottom: '-0.5rem' } : { width: '7rem', marginLeft: '3rem', marginBottom: '-0.75rem' }} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
                                <div style={isMobile ? { width: '12rem', height: '0.075rem', backgroundColor: '#5786a2' } : { width: '18rem', height: '0.075rem', backgroundColor: '#5786a2' }} />
                                <div style={{ width: '0.5rem', height: '0.5rem', borderRadius: '0.5rem', backgroundColor: '#5786a2' }} />
                            </div>
                            <Typography fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.15rem', marginTop: '1.5rem', width: '90%' } : { fontSize: '1.875rem', marginTop: '2.3125rem' }}>Contributes to healthy vision, good bone growth, a good immune system and good reproductive capacity.</Typography>
                        </div>
                        <div style={isMobile ? { display: 'flex', flexDirection: 'column', width: '90%', marginTop: '3rem' } : { display: 'flex', flexDirection: 'column', width: '21.37rem', marginTop: '-8rem', marginLeft: '-10rem' }}>
                            <div style={{ display: 'flex', alignItems: 'end' }}>
                                <Typography color='#5786a2' fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.75rem' } : { fontSize: '2.25rem' }}>Vitamin D:</Typography>
                                <img alt='Vitamin D' draggable='false' src={vitaminD} style={isMobile ? { width: '4rem' } : { width: '7rem', marginLeft: '3rem', marginBottom: '-0.75rem' }} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
                                <div style={isMobile ? { width: '12rem', height: '0.075rem', backgroundColor: '#5786a2' } : { width: '18rem', height: '0.075rem', backgroundColor: '#5786a2' }} />
                                <div style={{ width: '0.5rem', height: '0.5rem', borderRadius: '0.5rem', backgroundColor: '#5786a2' }} />
                            </div>
                            <Typography fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.15rem', marginTop: '1.5rem', width: '90%' } : { fontSize: '1.875rem', marginTop: '2.3125rem' }}>It plays a fundamental role in the formation and health of bones.</Typography>
                        </div>
                        <div style={isMobile ? { display: 'flex', flexDirection: 'column', width: '90%', marginTop: '3rem' } : { display: 'flex', flexDirection: 'column', width: '24.5rem', marginTop: '-5.5rem' }}>
                            <div style={{ display: 'flex', alignItems: 'end' }}>
                                <Typography color='#5786a2' fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.75rem' } : { fontSize: '2.25rem' }}>Selenium:</Typography>
                                <img alt='selenium' draggable='false' src={selenium} style={isMobile ? { width: '4rem' } : { width: '7rem', marginLeft: '3rem', marginBottom: '-0.5rem' }} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
                                <div style={isMobile ? { width: '12rem', height: '0.075rem', backgroundColor: '#5786a2' } : { width: '18rem', height: '0.075rem', backgroundColor: '#5786a2' }} />
                                <div style={{ width: '0.5rem', height: '0.5rem', borderRadius: '0.5rem', backgroundColor: '#5786a2' }} />
                            </div>
                            <Typography fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.15rem', marginTop: '1.5rem', width: '90%' } : { fontSize: '1.875rem', marginTop: '2.3125rem' }}>Very important nutrient that works with other vitamins to counteract the damage your body suffers.</Typography>
                        </div>
                    </div>
                </div>
                <Typography color='#5786a2' fontFamily='DM Serif Display' style={isMobile ? { fontSize: '2rem', marginTop: '4rem' } : { fontSize: '4.5rem', marginTop: '12.75rem' }}>Life Cycle of a Salmon:</Typography>
                <Typography textAlign='center' fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.15rem', marginTop: '3rem', width: '90%' } : { fontSize: '1.875rem', marginTop: '3rem', width: '90.5rem' }}>These incredible Fish have a life cycle of about 2 years. They lay their eggs in the Naknek freshwater and spend close to a year there before entering the Pacific Ocean. The next year of their life is spent eating the crustaceans of the sea giving them a very naturally bright color rich in vitamins and nutrients. They finally return miraculously to the same river where they were born following their instinctive God given GPS. They are carefully harvested at their most mature phase as they return to die in the rivers freshwater.</Typography>
                <img alt='life cycle of salmon' draggable='false' src={lifeCycleSalmon} style={isMobile ? { width: '100%', marginTop: '2rem' } : { width: '90rem', marginTop: '10.3125rem' }} />
                <div style={isMobile ? { display: 'flex', flexDirection: 'column' } : { display: 'flex', marginBottom: '13.75rem', marginTop: '17.125rem' }}>
                    <img alt='Salmon Bowl' draggable='false' src={salmon} style={isMobile ? { width: '100%', marginTop: '2rem' } : { height: '46rem', marginRight: '3rem' }} />
                    <div style={isMobile ? { display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' } : { display: 'flex', flexDirection: 'column', width: '44.4rem' }}>
                        <Typography color='#5786a2' fontFamily='DM Serif Display' style={isMobile ? { fontSize: '2rem', marginTop: '4rem' } : { fontSize: '4.5rem' }}>Conclusion</Typography>
                        <Typography fontFamily='DM Sans Variable' style={isMobile ? { fontSize: '1.15rem', marginTop: '2rem', width: '90%', textAlign: 'center' } : { fontSize: '1.875rem', marginTop: '3rem' }}>Regular consumption of Alaskan salmon is an excellent way to get the benefits of Omega-3 for heart health. Omega-3 fatty acids in Alaskan salmon have the ability to reduce the levels of cholesterol and triglycerides in the blood, which helps prevent heart disease and other health problems. Therefore, it is important to incorporate Alaskan salmon into a healthy and balanced diet.</Typography>
                        <Button
                            variant='contained'
                            component={RouterLink}
                            to="/ReserveYourSalmon"
                            onClick={() => handleClick()}
                            style={isMobile ?
                                {
                                    backgroundColor: '#5786a2',
                                    fontSize: '1.15rem',
                                    fontFamily: 'DM Serif Display',
                                    marginTop: '4rem',
                                    width: '21rem',
                                    borderRadius: 0,
                                    marginBottom: '4rem',
                                    '&:hover': {
                                        backgroundColor: alpha('#5786a2', 0.75)
                                    }
                                } : {
                                    backgroundColor: '#5786a2',
                                    fontSize: '2.67rem',
                                    fontFamily: 'DM Serif Display',
                                    marginTop: '8.4375rem',
                                    width: '35.51rem',
                                    height: '7',
                                    borderRadius: 0,
                                    marginBottom: '2.25rem',
                                    '&:hover': {
                                        backgroundColor: alpha('#5786a2', 0.75)
                                    }
                                }}>
                            Reserve Your Salmon
                        </Button>
                    </div>
                </div>

                <Footer />
            </div>}
        </>
    );
}