import React from 'react';
import { Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

export default function Recipes() {
    const isMobileText = useMediaQuery('(max-width:515px)');

    const container = {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
    }

    const title = {
        textAlign: 'center',
        marginTop: '8.625rem',
        fontSize: '4.5rem',
        color: '#5686A1',
        fontFamily: 'DM Serif Display'
    }

    const mobileTitle = {
        textAlign: 'center',
        marginTop: '3rem',
        fontSize: '3rem',
        color: '#5686A1',
        fontFamily: 'DM Serif Display',
    }

    const comingSoonText = {
        textAlign: 'center',
        fontSize: '2rem',
        marginTop: '5rem',
        marginBottom: '20rem',
        color: '#5686A1',
        fontFamily: 'DM Sans Variable'
    }

    const comingSoonMobileText = {
        textAlign: 'center',
        fontSize: '1.5rem',
        marginTop: '5rem',
        marginBottom: '20rem',
        color: '#5686A1',
        fontFamily: 'DM Sans Variable',
    }

    const recipeContainer = {
        display: 'flex',
        flexDirection: 'column',
    }

    return (
        <div style={container}>
            <div style={recipeContainer}>
            <NavBar />
                <Typography sx={isMobileText ? mobileTitle : title}>Recipes</Typography>
                <Typography sx={isMobileText ? comingSoonMobileText : comingSoonText}>Coming Soon</Typography>
            </div>
            <Footer />
        </div>
    );
}